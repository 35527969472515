import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'

import * as Api from '@rushplay/api-client'
import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'
import * as Notifications from '@rushplay/notifications'

import * as Constants from './constants'
import { InputPasswordField } from './input-password-field'
import { QueryDrawer } from './query-drawer'
import { SubmitButton } from './submit-button'
import { useMenuQueries } from './use-menu-queries'

function dataSchema() {
  return {
    type: 'object',
    properties: {
      currentPassword: {
        type: 'string',
      },
      newPassword: {
        type: 'string',
        pattern: Constants.PasswordPattern,
      },
      confirmPassword: {
        type: 'string',
        pattern: Constants.PasswordPattern,
      },
    },
    required: ['currentPassword', 'newPassword', 'confirmPassword'],
  }
}

const preloadSchemaKeys = Forms.findTranslationKeys(
  'change-password',
  dataSchema,
  ['label', 'placeholder']
)
function ChangePasswordForm() {
  const dispatch = ReactRedux.useDispatch()
  const history = ReactRouter.useHistory()
  const translate = Herz.I18n.useTranslate()
  const { myProfileQuery, resetPasswordQuery } = useMenuQueries()
  const [showVisualFeedback, setShowVisualFeedback] = React.useState(false)

  const [passwordMismatchError, setPasswordMismatchError] = React.useState(null)

  const schema = React.useMemo(() => dataSchema(), [])

  function handleSubmit(errors, data) {
    if (Object.keys(errors).length !== 0) {
      return
    }

    if (data.newPassword !== data.confirmPassword) {
      setPasswordMismatchError('password-mismatch')
      setShowVisualFeedback(true)
      return
    }

    return dispatch(
      Api.changePassword(
        data.currentPassword,
        data.newPassword,
        data.confirmPassword,
        {
          success: () => history.push(`?${myProfileQuery}`),
          failure: error => {
            if (error.value.message) {
              setShowVisualFeedback(true)
              dispatch(
                Notifications.add({
                  message: error.value.message,
                  level: 'error',
                })
              )
            }
          },
          version: 2,
        }
      )
    )
  }

  return (
    <Forms.Provider
      name="change-password"
      schema={schema}
      onSubmit={handleSubmit}
    >
      <Common.Space mt={3} />
      <InputPasswordField
        scope="#/properties/currentPassword"
        suppressVisualFeedback
      />
      <Common.Box
        my={0}
        fontSize={1}
        textAlign="right"
        textDecoration="underline"
        color="header-text"
      >
        <ReactRouter.Link
          data-testid="login-password-recovery"
          to={`?${resetPasswordQuery}`}
        >
          <Common.Text
            color="static-white"
            textDecoration="underline"
            fontFamily="head"
          >
            {translate('forgot-password')}
          </Common.Text>
        </ReactRouter.Link>
      </Common.Box>
      <Common.Space mt={2} />
      <InputPasswordField
        scope="#/properties/newPassword"
        customErrorKey={passwordMismatchError}
        onClearCustomErrorKey={() => {
          setPasswordMismatchError(null)
          setShowVisualFeedback(false)
        }}
        suppressVisualFeedback={!showVisualFeedback}
      />
      <Common.Space mt={3} />
      <InputPasswordField
        scope="#/properties/confirmPassword"
        customErrorKey={passwordMismatchError}
        onClearCustomErrorKey={() => {
          setPasswordMismatchError(null)
          setShowVisualFeedback(false)
        }}
        suppressVisualFeedback={!showVisualFeedback}
      />
      <Common.Space mt={5} />
      <SubmitButton stretch>{translate('change-password.submit')}</SubmitButton>
    </Forms.Provider>
  )
}

export function ChangePasswordDrawer() {
  const history = ReactRouter.useHistory()
  const translate = Herz.I18n.useTranslate()
  const { authenticated } = Herz.Auth.useSession()
  const { myProfileQuery } = useMenuQueries()

  if (!authenticated) {
    return null
  }

  return (
    <QueryDrawer
      activeQueryName="change-password"
      title={translate('change-password.title')}
      onSecondaryAction={() => history.push(`?${myProfileQuery}`)}
    >
      <ChangePasswordForm />
    </QueryDrawer>
  )
}

Herz.I18n.Loader.preload(
  [
    'forgot-password',
    'change-password.title',
    'change-password.submit',
    ...preloadSchemaKeys,
  ],
  ChangePasswordDrawer
)

export default ChangePasswordDrawer
